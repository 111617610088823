<template>
    <b-container fluid>
        <div class="card card-primary">
            <div class="card-header">
                <div class="card-title">
                    Arbeitsplan
                </div>
            </div>
            <div class="card-body">
                <div class="text-right pb-2">
                    <button class="btn btn-primary btn-sm mr-1" @click="removeYear()">
                        - 1 Jahr
                    </button>
                    <button class="btn btn-primary btn-sm" @click="addYear()">
                        + 1 Jahr
                    </button>
                    <button type="button" class="btn btn-sm btn-secondary ml-1" data-widget="control-sidebar"><i class="fas fa-filter"></i></button>
                </div>
                <div class="card card-secondary card-outline">
                    <div class="card-body">
                        <div class="overflow-auto">
                            <ul class="pagination" v-if="weeks.length > 0">
                                <li class="page-item" v-for="week in weeks" :key="week.weeknumber + Math.random()*1000" :class="(week.weeknumber == selectedWeek) ? 'active' : ''" :ref="week.weeknumber == selectedWeek ? 'yes' : 'no'" @click="selectWeek(week.weeknumber)">
                                    <a class="page-link text-center" href="#">
                                        <div class="ribbon-wrapper ribbon-xs" v-if="week.active == 0">
                                            <div class="ribbon bg-danger"></div></div>
                                        <h3 class="page-year">{{ week.weeknumber }}</h3>
                                        <p>
                                            {{week.start_date | formatDate}} <br>
                                            bis
                                            {{week.end_date | formatDate}}
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card card-secondary card-outline">
                    <div class="card-body" v-if="weeks">
                        <WeeklyView :selectedWeek="selectedWeek" :groups="groups"></WeeklyView>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import WeeklyView from './components/WeeklyView.vue'
import moment from 'moment'
export default {
    name: "WorkplanIndex",
    title: "Arbeitsplan",
    components: {WeeklyView},
    data() {
        return {
            groups: [],
            stores: [],
            employees: [],
            weeks: [],
            year: moment().format('YYYY'),
            selectedWeek: 0,

            week_data: [],
            hasChanges: false,
        }
    },
    methods: {
        removeYear() {
            this.year-=1;
            this.getWeeks();
        },
        addYear() {
            this.year+=1;
            this.getWeeks();
        },
        selectWeek(weeknumber)
        {
            if(this.hasChanges == true)
            {
                this.$swal({
                    title: "Möchtest du die Änderungen wirklich verwerfen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, verwerfen!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.hasChanges = false;
                        this.selectWeek(weeknumber);
                    }
                });
            } else
            {
                this.selectedWeek = weeknumber;
            }
        },
        getEmployeeGroups()
        {
            this.axios
                .get("/employee/groups", {
                    params: {
                        order_by: 'desc'
                    }
                })
                .then((response) => {
                    this.groups = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Fehler beim laden der Mitarbeitergruppen'
                    })
                })
        },
        getStores()
        {
            this.axios
                .get("/stores")
                .then((response) => {
                    this.stores = response.data.data;
                    this.stores.push({
                        id: 0,
                        name: 'Schule',
                        business_hours: [],
                        open_sundays: [],
                    });
                    this.$nextTick(()=>{
                        if(this.$refs.yes != undefined)
                        {
                            this.$refs.yes[0].scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'center' });
                        }
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Fehler beim laden der Filialen'
                    })
                })
        },

        /*

            Generators

        */
        generateWeeks() {
            this.weeks = [];
            this.selectedWeek = moment().isoWeek();
            let dates = this.createRange(this.year + '-01-01', this.year + '-12-31');
            dates.forEach(element => {
                let exists = this.weeks.find(item => (item.weeknumber == element.isoWeek() && item.year == element.year()))
                if(exists == null)
                {
                    let find = this.week_data.find(curr => curr.week == element.isoWeek());
                    if(find != null)
                    {
                        this.weeks.push({
                            weeknumber: element.isoWeek(),
                            start_date: element.startOf('isoWeek').format('YYYY-MM-DD'),
                            end_date: element.endOf('isoWeek').format('YYYY-MM-DD'),
                            year: element.year(),
                            active: find.active,
                        });
                    } else
                    {
                        this.weeks.push({
                            weeknumber: element.isoWeek(),
                            start_date: element.startOf('isoWeek').format('YYYY-MM-DD'),
                            end_date: element.endOf('isoWeek').format('YYYY-MM-DD'),
                            year: element.year(),
                            active: 0,
                        });
                    }
                }
            });
        },

        /*

            Helpers

        */
        createRange(startDate, stopDate) {
            let dates = [];
            let currentDate = moment(this.parseDate(startDate));
            while(currentDate.isSameOrBefore(this.parseDate(stopDate)))
            {
                dates.push(moment(currentDate).locale('DE'));
                currentDate = currentDate.add(1, 'days');
            }
            return dates;
        },
        parseDate(date)
        {
            let element = date.split(/[\s-:]+/);
            return {
                year: parseInt(element[0]),
                month: parseInt(element[1])-1,
                date: parseInt(element[2]),
            }
        },
        parseTime(time)
        {
            let element = time.split(/[\s-:]+/);
            return {
                hour: parseInt(element[0]),
                minute: parseInt(element[1]),
            }
        },
        getEmployees()
        {
            this.axios
                .get("/employees", {
                    params: {
                        per_page: 10000,
                    }
                })
                .then((response) => {
                    this.employees = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Mitarbeiter ist etwas schief gelaufen'
                    })
                })
        },
        getWeeks() {
            this.axios
                .get("/workplan-weeks", {
                    params: {
                        year: this.year
                    }
                })
                .then((response) => {
                    this.week_data = response.data.data;
                    this.generateWeeks();
                    this.getStores();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Wochen ist etwas schief gelaufen'
                    })
                })
        }
    },
    mounted() {
        this.getEmployeeGroups();
        this.getEmployees();

        this.getWeeks();
    },
    beforeRouteLeave(to, from, next)
    {
        if(this.hasChanges == true)
        {
        this.$swal({
            title: "Möchtest du die Änderungen wirklich verwerfen?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Ja, verwerfen!!!`,
            denyButtonText: `Abbrechen`,
        })
        .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.hasChanges = false;
                next(true);
            } else
            {
                next(false);
            }
        });
        } else
        {
        next(true);
        }
    }
}
</script>

<style>

</style>